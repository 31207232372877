import { makeStyles } from "tss-react/mui";
import { boxContainer, gridContainer } from "../MainContent/MainContent.styles";

export const defaultMyEsgContentStyles = {
  titlePosition: {
    marginBottom: "30px",
  },
  subtitle: {
    marginBottom: "30px",
  },
  checkSymbol: {
    margin: "30px 0px 30px 0px",
    display: "flex",
    justifyContent: "center",
  },
  subtitle2: {
    marginBottom: "22px",
  },
  section: {
    padding: "0px, 15px, 0px, 15px",
  },
  gridContainer,
  boxContainer,
  gridContainer2: {
    paddingBottom: "60px",
  },
  title: {
    lineHeight: "52px",
  },
  button: {
    paddingRight: "150px",
    paddingTop: "10px",
  },
  containerBulletPoints: {
    padding: "0px 50px 0px 50px",
  },
  itemBulletPoint1: {
    padding: "0px 15px 0px 0px",
  },
  itemBulletPoint2: {
    padding: "0px 15px 0px 15px",
  },
  itemBulletPoint3: {
    padding: "0px 0px 0px 15px",
  },
  myEsgContent1Section: {
    paddingBottom: "15px",
  },
};

export const MyESGContentStyles = makeStyles()({
  ...defaultMyEsgContentStyles,
});
