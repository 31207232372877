import { makeStyles } from "tss-react/mui";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const PageStyles = makeStyles()({
  contentContainer: {
    padding: "74px 0 0 0",
    minHeight: "calc(100% - 74px - 96px)",
    position: "relative",
    overflow: "auto",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
});
