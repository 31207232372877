import { makeStyles } from 'tss-react/mui';
import { defaultMyEsgContentStyles } from "../MyESGContent/MyESGContent.styles";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const MyESGContent1Styles = makeStyles()({
  ...defaultMyEsgContentStyles,
  checkSymbol: {
    margin: "38px 0px 30px 0px",
  },
});
