export const ExclamationMark = (props: any) => (
  <div>
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 0C13.4297 0 0 13.4297 0 30C0 46.5703 13.4297 60 30 60C46.5703 60 60 46.5703 60 30C60 13.4297 46.5703 0 30 0ZM30 54.375C16.5586 54.375 5.625 43.4402 5.625 30C5.625 16.5598 16.5586 5.625 30 5.625C43.4414 5.625 54.375 16.5598 54.375 30C54.375 43.4402 43.4414 54.375 30 54.375ZM30 35.625C31.5527 35.625 32.8125 34.3652 32.8125 32.8125V17.8125C32.8125 16.2656 31.5586 15 30 15C28.4414 15 27.1875 16.2656 27.1875 17.8125V32.8125C27.1875 34.3711 28.4531 35.625 30 35.625ZM30 39.5039C27.9656 39.5039 26.3156 41.1539 26.3156 43.1883C26.3203 45.2227 27.9609 46.875 30 46.875C32.0391 46.875 33.6844 45.225 33.6844 43.1906C33.6797 41.1562 32.0391 39.5039 30 39.5039Z"
        fill="#E05469"
      />
    </svg>
  </div>
);
