import { makeStyles } from "tss-react/mui";

import { defaultMyEsgContentStyles } from "../MyESGContent/MyESGContent.styles";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const PinVerificationResultStyles = makeStyles()({
  ...defaultMyEsgContentStyles,
  flexItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  pinVerificationSymbol: {
    paddingBottom: "10px",
  },
  pinVerificationTitleOnSuccess: {
    paddingBottom: "40px",
  },
  pinVerificationTitleOnFailure: {
    paddingBottom: "10px",
  },
  pinVerificationButton: {
    paddingTop: "10px 0px 60px 0px",
    width: "70%",
  },
  pinVerificationButtonLabel: {
    marginBottom: "30px",
    textTransform: "none",
    fontWeight: 600,
    lineHeight: "26px",
    letterSpacing: "0.5px",
    fontStyle: "normal",
  },
  contentContainer: {
    display: "inline-flex",
    gap: "20px",
    justifyContent: "center",
    flexDirection: "column",
  },
  pinVerificationGridContainer: {
    display: "flex",
    backgroundColor: "#F5F5F5",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    minHeight: "calc(100vh - 74px - 96px)",
  },
  pinVerificationBoxContainer: {
    maxWidth: "1055px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "60px 0px",
    height: "calc(100vh - 74px - 96px))",
    minHeight: "calc(100vh - 74px - 96px)",
  },
});
