import { Box } from "@mui/material";

import { ContentProps } from "../../types/contentProps";
import { MainContent } from "../MainContent/MainContent";

export const Home: React.FC<ContentProps> = ({
  title,
  shortCodeFromUrl,
  isShortCodeFromUrlValid = false,
  verifierShortName,
  description,
}) => {
  return (
    <Box>
      <MainContent
        title={title}
        shortCodeFromUrl={shortCodeFromUrl}
        isShortCodeFromUrlValid={isShortCodeFromUrlValid}
        verifierShortName={verifierShortName}
        description={description}
        displayImage={false}
      ></MainContent>
    </Box>
  );
};
