import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";

import { CheckSymbol } from "./CheckSymbol";
import { MyESGContentStyles } from "./MyESGContent.styles";

export const MyESGContent = () => {
  const { classes } = MyESGContentStyles();

  return (
    <Box
      sx={{ backgroundColor: "#FFFFFF !important" }}
      className={classes.gridContainer}
    >
      <Grid container className={classes.boxContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={classes.titlePosition}>
            <Typography variant="h1" className={classes.title}>
              {t("myEsgContent.title")}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={classes.subtitle}>
            <Typography variant="h3">{t("myEsgContent.subtitle")}</Typography>
          </div>
        </Grid>

        <Grid container item className={classes.containerBulletPoints}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            padding={0}
            className={classes.itemBulletPoint1}
          >
            <div className={classes.checkSymbol}>
              <CheckSymbol></CheckSymbol>
            </div>

            <div className={classes.subtitle2}>
              <Typography align="left" variant="h3">
                {t("myEsgContent.benefit1Subtitle")}
              </Typography>
            </div>

            <div className={classes.section}>
              <Typography align="left">
                {t("myEsgContent.benefit1Statement")}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            padding={0}
            className={classes.itemBulletPoint2}
          >
            <div className={classes.checkSymbol}>
              <CheckSymbol></CheckSymbol>
            </div>

            <div className={classes.subtitle2}>
              <Typography align="left" variant="h3">
                {t("myEsgContent.benefit2Subtitle")}
              </Typography>
            </div>

            <div className={classes.section}>
              <Typography align="left">
                {t("myEsgContent.benefit2Statement")}
              </Typography>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            padding={0}
            className={classes.itemBulletPoint3}
          >
            <div className={classes.checkSymbol}>
              <CheckSymbol></CheckSymbol>
            </div>

            <div className={classes.subtitle2}>
              <Typography align="left" variant="h3">
                {t("myEsgContent.benefit3Subtitle")}
              </Typography>
            </div>

            <div className={classes.section}>
              <Typography align="left">
                {t("myEsgContent.benefit3Statement")}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
