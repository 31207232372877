export const CheckSymbol = (props: any) => (
  <div>
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9934 7.51079C42.3972 7.51079 52.4877 17.6021 52.4877 30.0053C52.4877 42.4114 42.3972 52.5021 29.9934 52.5021C17.59 52.5021 7.49852 42.4114 7.49852 30.0053C7.4982 17.6021 17.59 7.51079 29.9934 7.51079ZM29.9934 0.0125122C13.4289 0.0125122 0 13.4409 0 30.0053C0 46.5703 13.4289 60 29.9934 60C46.5589 60 59.9859 46.5703 59.9859 30.0053C59.9856 13.4409 46.5589 0.0125122 29.9934 0.0125122ZM45.6337 23.3595L40.337 18.0522L24.6019 33.7532L18.7611 27.9112L13.4595 33.2116L24.5972 44.3518L45.6337 23.3595Z"
        fill="#5BC4F2"
      />
    </svg>
  </div>
);
