export const CheckIcon = (props: any) => (
  <div style={{}}>
    <svg
      width="21.43"
      height="19.5"
      viewBox="0 0 26.43 8.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="scale(1)">
        <path
          d="M19.3471 0.912545L7.01695 13.2427L2.48639 8.71216C2.26673 8.49251 1.91057 8.49251 1.69087 8.71216L0.36506 10.038C0.145403 10.2576 0.145403 10.6138 0.36506 10.8335L6.61917 17.0876C6.83882 17.3073 7.19498 17.3073 7.41468 17.0876L21.4684 3.03387C21.6881 2.81421 21.6881 2.45806 21.4684 2.23836L20.1426 0.912545C19.9229 0.692889 19.5668 0.692889 19.3471 0.912545V0.912545Z"
          fill="#009EE2"
        />
      </g>
    </svg>
  </div>
);
