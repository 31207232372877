import { useEffect } from "react";

import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router";

import { CheckSymbol } from "./CheckSymbol";
import { ExclamationMark } from "./ExclamationMark";
import { PersonImage } from "./PersonImage";
import { PinVerificationResultStyles } from "./PinVerificationResult.styles";

export const PinVerificationResult = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.status === undefined) {
      navigate("/");
    }
  });

  const successStatus = location.state?.status === "success";
  const redirectUrl = location.state?.redirectUrl;
  const { classes } = PinVerificationResultStyles();

  return (
    <Box className={classes.pinVerificationGridContainer}>
      <Grid container className={classes.pinVerificationBoxContainer}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {successStatus ? (
            <Box className={classes.contentContainer}>
              <div className={classes.pinVerificationSymbol}>
                <CheckSymbol />
              </div>

              <div className={classes.pinVerificationTitleOnSuccess}>
                <Typography variant="h1">
                  {t("pinResult.titleOnSuccess")}
                </Typography>
              </div>

              <div className={classes.flexItem}>
                <Typography variant="h2">
                  {t("pinResult.subtitleOnSuccess")}
                </Typography>
              </div>

              <div className={classes.flexItem}>
                <Typography>{t("pinResult.sectionOnSuccess")}</Typography>
              </div>

              <div className={classes.button}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    window.location.replace(redirectUrl);
                  }}
                  className={classes.pinVerificationButtonLabel}
                >
                  <div>{t("pinResult.buttonOnSuccess")}</div>
                </Button>
              </div>
            </Box>
          ) : (
            <Box className={classes.contentContainer}>
              <div className={classes.pinVerificationSymbol}>
                <ExclamationMark />
              </div>
              <div className={classes.pinVerificationTitleOnFailure}>
                <Typography variant="h1">
                  {t("pinResult.titleOnFailure")}
                </Typography>
              </div>

              <div className={classes.flexItem}>
                <Typography>{t("pinResult.sectionOnFailure")}</Typography>
              </div>

              <Box>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "0px 0px 0px 70px",
                    margin: "0px",
                  }}
                >
                  <li>
                    <ListItemText>
                      <Typography align="left">
                        {t("pinResult.bulletPoint1")}
                      </Typography>
                    </ListItemText>
                  </li>
                  <li>
                    <ListItemText>
                      <Typography align="left">
                        {t("pinResult.bulletPoint2")}
                      </Typography>
                    </ListItemText>
                  </li>
                  <li>
                    <ListItemText>
                      <Typography align="left">
                        {t("pinResult.bulletPoint3")}
                      </Typography>
                    </ListItemText>
                  </li>
                </ul>
              </Box>

              <div className={classes.pinVerificationButton}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    window.location.replace(redirectUrl);
                  }}
                  className={classes.pinVerificationButtonLabel}
                >
                  <div>{t("pinResult.buttonOnFailure")}</div>
                </Button>
              </div>
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          display={{ xs: "none", sm: "none", md: "none", lg: "flex" }}
          className={classes.contentContainer}
          height="100%"
        >
          <PersonImage />
        </Grid>
      </Grid>
    </Box>
  );
};
