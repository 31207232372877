export const CheckSymbol = (props: any) => (
  <div>
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5703 39.8203C27.293 41.0977 25.207 41.0977 23.9297 39.8203L16.4297 32.3203C15.1523 31.043 15.1523 28.957 16.4297 27.6797C17.707 26.4023 19.793 26.4023 21.0703 27.6797L26.25 32.8594L38.9297 20.1797C40.207 18.9023 42.293 18.9023 43.5703 20.1797C44.8477 21.457 44.8477 23.543 43.5703 24.8203L28.5703 39.8203ZM60 30C60 46.5703 46.5703 60 30 60C13.4297 60 0 46.5703 0 30C0 13.4297 13.4297 0 30 0C46.5703 0 60 13.4297 60 30ZM30 5.625C16.5352 5.625 5.625 16.5352 5.625 30C5.625 43.4648 16.5352 54.375 30 54.375C43.4648 54.375 54.375 43.4648 54.375 30C54.375 16.5352 43.4648 5.625 30 5.625Z"
        fill="#9CCC3F"
      />
    </svg>
  </div>
);
