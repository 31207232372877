import { makeStyles } from "tss-react/mui";
import { defaultMyEsgContentStyles } from "../MyESGContent/MyESGContent.styles";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const MyESGContentStyle2 = makeStyles()({
  ...defaultMyEsgContentStyles,
  checkIcon: {
    top: 0,
    left: 0,
    minWidth: "0px !important",
  },
  myEsgContent2ContainerBulletPoints: {
    padding: "0px 14px 0px 14px",
  },
  myEsgContent2Subtitle2: {
    paddingBottom: "7px",
  },
});
