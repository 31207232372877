import { Box } from "@mui/material";
import { ContentProps } from "../../types/contentProps";
import { MainContent } from "../MainContent/MainContent";
import { MyESGContent } from "../MyESGContent/MyESGContent";
import { MyESGContent1 } from "../MyESGContent1/MyESGContent1";
import { MyESGContent2 } from "../MyESGContent2/MyESGContent2";
import { MyESGContent3 } from "../MyESGContent3/MyESGContent3";

export const MyESG: React.FC<ContentProps> = ({
  title,
  shortCodeFromUrl,
  isShortCodeFromUrlValid,
  verifierShortName,
  description,
}) => {
  return (
      <Box>
        <MainContent
            title={title}
            shortCodeFromUrl={shortCodeFromUrl}
            isShortCodeFromUrlValid={isShortCodeFromUrlValid}
            verifierShortName={verifierShortName}
            description={description}
            displayImage={true}
        ></MainContent>

        <MyESGContent />
        <MyESGContent1 />
        <MyESGContent2 />
        <MyESGContent3 />
      </Box>
  );
};
