import { makeStyles } from "tss-react/mui";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const MyFooterStyle = makeStyles()({
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    bottom: "0",
    left: "0",
    right: "0",
    backgroundColor: "rgb(76,76,76)",
    height: "96px",
    width: "100%",
  },
});
