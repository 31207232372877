import { useEffect, useState } from "react";

import { Button, Grid, TextField, Typography } from "@mui/material";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ContentStyle } from "./Content.style";
import { ErrorResponse } from "../../interfaces/errorResponse";
import { ContentProps } from "../../types/contentProps";
import { VerifyPinResponse } from "../../types/verifyPinResponse";

export const Content: React.FC<ContentProps> = ({
  shortCodeFromUrl,
  description,
  isShortCodeFromUrlValid,
  title,
}: ContentProps) => {
  const [shortCode, setShortCode] = useState<string | null>("");
  const [shortCodeError, setShortCodeError] = useState<string | null>(null);
  const [pin, setPin] = useState<string | null>("");
  const [pinError, setPinError] = useState<string | null>(null);
  const [showShortCode, setShowShortCode] = useState<boolean>();
  const [message, setMessage] = useState<string>();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = ContentStyle();

  useEffect(() => {
    setShortCode(shortCodeFromUrl);

    if (!shortCodeFromUrl) {
      setShowShortCode(true);
    }

    if (shortCodeFromUrl && !isShortCodeFromUrlValid) {
      setShortCode(shortCodeFromUrl);
      setShowShortCode(true);
      setShortCodeError(`${t("showErrorMessage.shortCodeInvalid")}`);
    }

    if (shortCodeFromUrl && isShortCodeFromUrlValid) {
      setShowShortCode(false);
    }
  }, [shortCodeFromUrl, isShortCodeFromUrlValid, t]);

  const http = axios.create({
    // eslint-disable-next-line no-underscore-dangle
    baseURL: window?._env_?.PIN_ORDER_SERVICE,
  });

  const isFormValid = shortCodeError === null && pinError === null;

  const validateShortcode = () => {
    if (shortCode?.trim().length === 0) {
      setShortCodeError(`${t("showErrorMessage.shortCodeIsRequired")}`);
    } else {
      setShortCodeError(null);
    }
  };

  const validatePin = () => {
    if (pin?.trim().length === 0) {
      setPinError(`${t("showErrorMessage.pinIsRequired")}`);
    } else {
      setPinError(null);
    }
  };

  const setValueForShortCode = (e: any) => {
    setShortCode(e.target.value);
    setShortCodeError(null);
  };

  const setValueForPin = (e: any) => {
    setPin(e.target.value);
    setPinError(null);
  };

  const verifyPin = async (event: any) => {
    try {
      event.preventDefault();

      if (shortCode?.trim().length === 0) {
        setShortCodeError(`${t("showErrorMessage.shortCodeIsRequired")}`);
        return;
      } else if (pin?.trim().length === 0) {
        setPinError(`${t("showErrorMessage.pinIsRequired")}`);
        return;
      }

      const result: AxiosResponse<VerifyPinResponse> = await http.post(
        "/order/voucher",
        {
          pin: pin,
          shortCode: shortCode,
        }
      );

      switch (result.data.status) {
        case "created": {
          if (result.data.retries > 1) {
            setMessage(
              `${t("showErrorMessage.created.plural", {
                numberOfAttempts: `${result.data.retries}`,
              })}`
            );
          } else {
            setMessage(
              `${t("showErrorMessage.created.single", {
                numberOfAttempts: `${result.data.retries}`,
              })}`
            );
          }
          setShowMessage(true);
          break;
        }
        case "failed": {
          setMessage(`${t("showErrorMessage.failed")}`);
          setShowMessage(true);
          setDisable(true);
          navigate("/pin-result", {
            state: {
              status: result.data.status,
              redirectUrl: result.data.redirect,
            },
          });
          break;
        }
        case "expired": {
          setMessage(`${t("showErrorMessage.expired")}`);
          setShowMessage(true);
          setDisable(true);

          break;
        }
        case "success": {
          navigate("/pin-result", {
            state: {
              status: result.data.status,
              redirectUrl: result.data.redirect,
            },
          });
          return;
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError<ErrorResponse> = error;

        if (
          axiosError?.response?.data.message === "Invalid ShortCode provided."
        ) {
          setShortCodeError(`${t("showErrorMessage.shortCodeInvalid")}`);
        }
      }
      console.log(`This error comes not from axios: ${error}`);
    }
  };

  return (
    <form onSubmit={verifyPin} className={classes.contentContainer}>
      <Typography variant="h1" className={classes.titlePosition}>
        <span className={classes.titleStyle}>
          {title ? title : `${t("title")}`}{" "}
        </span>
      </Typography>

      <div className={classes.subtitle}>
        <Typography variant="h3">{t("subtitle")}</Typography>
      </div>

      {showMessage && (
        <div
          style={{
            textAlign: "left",
            color: "#e85c73",
          }}
        >
          {message}
        </div>
      )}

      <Grid item xs={12} sm={12} md={12} lg={9} padding="0">
        {showShortCode && (
          <TextField
            placeholder={`${t("inputFieldIdentifier")}`}
            id="short-code"
            type="text"
            value={shortCode}
            onChange={setValueForShortCode}
            helperText={shortCodeError}
            onBlur={validateShortcode}
            error={shortCodeError !== null}
          />
        )}
        <div style={{ marginTop: "20px" }}></div>

        <TextField
          placeholder={`${t("inputFieldActivationCode")}`}
          id="pin"
          type="text"
          value={pin}
          onChange={setValueForPin}
          helperText={pinError}
          onBlur={validatePin}
          error={pinError !== null}
        />
        <div style={{ marginTop: "20px" }}></div>
        <Button
          variant="contained"
          type="submit"
          disabled={!isFormValid || disable}
          className={classes.button}
        >
          <div>{t("button")}</div>
        </Button>
      </Grid>
    </form>
  );
};
