import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { t } from "i18next";

import { CheckIcon } from "./CheckIcon";
import { MyESGContentStyle2 } from "./MyESGContent2.styles";
import { CheckSymbol } from "../MyESGContent/CheckSymbol";

export const MyESGContent2 = () => {
  const { classes } = MyESGContentStyle2();
  return (
    <Box
      sx={{ backgroundColor: "#fff !important" }}
      className={classes.gridContainer}
    >
      <Grid container className={classes.boxContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={classes.titlePosition}>
            <Typography variant="h1" className={classes.title}>
              {t("myEsgContent2.title")}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={classes.subtitle}>
            <Typography variant="h3">{t("myEsgContent2.subtitle")}</Typography>
          </div>
        </Grid>

        <Grid
          container
          item
          className={classes.myEsgContent2ContainerBulletPoints}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            container
            direction="column"
            padding={0}
          >
            <div className={classes.checkSymbol}>
              <CheckSymbol></CheckSymbol>
            </div>
            <div className={classes.myEsgContent2Subtitle2}>
              <Typography align="center" variant="h2">
                {t("myEsgContent2.benefit1Subtitle")}
              </Typography>
            </div>

            <List dense={true}>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint1Environment")}
                    <span>
                      <sub>2</sub>
                    </span>
                    {t("myEsgContent2.bulletPoint1EnvironmentPart2")}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint2Environment")}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint3Environment")}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint4Environment")}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            container
            direction="column"
            padding={0}
          >
            <div className={classes.checkSymbol}>
              <CheckSymbol></CheckSymbol>
            </div>

            <div className={classes.myEsgContent2Subtitle2}>
              <Typography align="center" variant="h2">
                {t("myEsgContent2.benefit2Subtitle")}
              </Typography>
            </div>

            <List dense={true}>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint1Social")}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint2Social")}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint3Social")}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint4Social")}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            container
            direction="column"
            padding={0}
          >
            <div className={classes.checkSymbol}>
              <CheckSymbol></CheckSymbol>
            </div>

            <div className={classes.myEsgContent2Subtitle2}>
              <Typography align="center" variant="h2">
                {t("myEsgContent2.benefit3Subtitle")}
              </Typography>
            </div>

            <List dense={true}>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint1Governance")}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint2Governance")}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint3Governance")}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                style={{
                  alignItems: "baseline",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t("myEsgContent2.bulletPoint4Governance")}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
