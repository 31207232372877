import "./App.scss";
import { useEffect, useState } from "react";

import { ThemeProvider } from "@mui/material";
import axios, { AxiosError, AxiosResponse } from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Home } from "./components/Home/Home";
import { MyESG } from "./components/MyESG/MyESG";
import { Page } from "./components/Page/Page";
import { PageNotFound } from "./components/PageNotFound/PageNotFound";
import { PinVerificationResult } from "./components/PinVerificationResult/PinVerificationResult";
import { crefoTheme } from "./CrefoTheme";
import { ErrorResponse } from "./interfaces/errorResponse";
import { ContentProps } from "./types/contentProps";
import { PinOrderContent } from "./types/pinOrderContent";

const pathname = window.location.pathname;
const parts = pathname.split("/").filter((part) => part !== "");

let bodyProps: ContentProps = {
  verifierShortName: parts[0] ?? "",
  shortCodeFromUrl: parts[1] ?? "",
  description: "",
  isShortCodeFromUrlValid: false,
  title: parts[0] ?? "",
};

export const App = () => {
  const [pinOrderContent, setPinOrderContent] =
    useState<PinOrderContent | null>();
  const [shortCodeFromUrl, setShortCodeFromUrl] = useState<string>("");
  const [isShortCodeFromUrlValid, setIsShortCodeFromUrlValid] =
    useState<boolean>(false);
  const [verifierNotFound, setVerifierNotFound] = useState(false);

  useEffect(() => {
    const http = axios.create({
      // eslint-disable-next-line no-underscore-dangle
      baseURL: window?._env_?.PIN_ORDER_SERVICE,
    });

    const getPinOrderContentByShortName = async (
      verifierShortName: ContentProps["verifierShortName"]
    ) => {
      try {
        if (!verifierShortName || verifierShortName === "pin-result") {
          return;
        }
        const result: AxiosResponse<PinOrderContent> = await http.get(
          `/presets/content/${verifierShortName}`
        );

        bodyProps.description = result.data.description;
        setPinOrderContent(result.data);
        if (bodyProps.shortCodeFromUrl) {
          checkShortCodeFromUrl(bodyProps.shortCodeFromUrl);
        }
      } catch (error) {
        setVerifierNotFound(true);
        console.log(`Could not load content for verifier ${verifierShortName}`);
      }
    };

    const checkShortCodeFromUrl = async (shortCode: string) => {
      try {
        if (!shortCode) {
          setShortCodeFromUrl(shortCode);
          return;
        }

        const result: AxiosResponse<PinOrderContent> = await http.get(
          `/pin-ident-order/shortcode/${shortCode}/valid`
        );

        if (result) {
          setShortCodeFromUrl(shortCode);
          setIsShortCodeFromUrlValid(true);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError: AxiosError<ErrorResponse> = error;
          if (axiosError.response) {
            if (axiosError.response.data.error === "Not Found") {
              console.log(`The shortCode: ${shortCode} is not valid.`);
              setShortCodeFromUrl(shortCode);
              setIsShortCodeFromUrlValid(false);
            }
          }
        }
      }
    };

    if (bodyProps.verifierShortName) {
      getPinOrderContentByShortName(bodyProps.verifierShortName);
    }
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={crefoTheme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Page>
                  <Home
                    title={pinOrderContent?.title}
                    shortCodeFromUrl={shortCodeFromUrl}
                    isShortCodeFromUrlValid={isShortCodeFromUrlValid}
                    verifierShortName={bodyProps.verifierShortName}
                    description={bodyProps.description}
                  />
                </Page>
              }
            ></Route>
            <Route
              path="myesg/*"
              element={
                <Page>
                  <MyESG
                    title={pinOrderContent?.title}
                    shortCodeFromUrl={shortCodeFromUrl}
                    isShortCodeFromUrlValid={isShortCodeFromUrlValid}
                    verifierShortName={bodyProps.verifierShortName}
                    description={bodyProps.description}
                  />
                </Page>
              }
            ></Route>
            <Route
              path="/pin-result"
              element={
                <Page>
                  <PinVerificationResult />
                </Page>
              }
            ></Route>
            <Route
              path="/*"
              element={
                <Page>
                  <PageNotFound
                    verifierNotFound={verifierNotFound}
                  ></PageNotFound>
                </Page>
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};
