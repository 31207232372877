import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { t } from "i18next";

import { CreditReformLogoSmall } from "./CreditReformLogoSmall";
import { MyESGContent3Style } from "./MyESGContent3.styles";

export const MyESGContent3 = () => {
  const { classes } = MyESGContent3Style();

  return (
    <Box className={classes.gridContainer}>
      <Grid container className={classes.boxContainer}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography variant="h1" style={{ marginBottom: "30px" }}>
            {t("myEsgContent3.title")}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box display={"flex"} justifyContent={"center"}>
            <Paper className={classes.paper}>
              <div className={classes.logo}>
                <CreditReformLogoSmall />
              </div>
              <div className={classes.subtitle}>
                <Typography variant="h2">
                  {t("myEsgContent3.subtitle")}
                </Typography>
              </div>
              <div className={classes.section}>
                <Typography>{t("myEsgContent3.section")}</Typography>
              </div>
              <Button
                variant="contained"
                type="submit"
                onClick={() =>
                  window.location.replace(
                    "https://www.creditreform.de/creditreform/kontakt"
                  )
                }
              >
                <div className={classes.labelButton}>
                  {t("myEsgContent3.button")}
                </div>
              </Button>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
