import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { CreditreformLogo } from "./CreditreformLogo";
import { CreditReformLogoSmall } from "./CreditReformLogoSmall";
import crefoTrustLogo from "./CrefoTrustLogo.svg";
import { NavigationBarStyles } from "./NavigationBar.styles";

export const NavigationBar = () => {
  const pathname = window.location.pathname;
  const standardUrl = pathname === "/";
  const { classes } = NavigationBarStyles()

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        className={classes.appBar}
      >
        <Toolbar className={classes.flexCenter}>
          <Box
            className={classes.flexCenter}
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <CreditreformLogo></CreditreformLogo>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
            display={"flex"}
            alignItems={"center"}
          >
            <CreditReformLogoSmall />
          </Box>

          {standardUrl && (
            <Box
              display={"flex"}
              alignItems={"center"}
              className={classes.flexEnd}
            >
              <img
                  height={"70px"}
                  width={"70px"}
                srcSet={crefoTrustLogo}
                alt={"CrefoTrust"}
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
