export const CreditReformLogoSmall = (props: any) => (
    <svg
        width="38"
        height="52"
        viewBox="0 0 76 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.28869 20.8105C5.47673 12.6886 11.7431 6.17482 19.7099 2.69978C28.9399 -1.30848 39.488 -0.83377 48.3225 3.99184L41.9613 17.2669C36.9698 14.2866 30.863 13.8905 25.5358 16.2072C21.131 18.126 17.6671 21.7281 15.9052 26.2178C14.1416 30.7074 14.2247 35.7169 16.1346 40.1447C18.0444 44.5741 21.6247 48.0559 26.0909 49.8293C30.5539 51.6045 35.5354 51.5209 39.9401 49.5987C45.2092 47.2903 49.0754 42.6068 50.357 36.9722L64.374 41.3649C61.801 51.0913 54.9479 59.1011 45.7677 63.1044C29.1776 70.3386 9.89976 62.6848 2.70423 46.0033C-0.753081 37.9935 -0.899352 28.9323 2.28869 20.8105ZM60.685 86.3159H3.68922V75.0016H60.685V86.3159Z"
            fill="#009EE2"
        />
      </g>
    </svg>
);
