import { makeStyles } from "tss-react/mui";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const PageNotFoundStyle = makeStyles()({
  titlePosition: {
    marginTop: "90px",
    marginBottom: "30px",
    marginLeft: "10px",
    "@media (max-width: 735px)": {
      marginTop: "84px",
      marginBottom: "20px",
      marginLeft: "10px",
    },
  },
  subtitle: {
    marginBottom: "30px",
  },
  checkSymbol: {
    margin: "38px 0px 30px 0px",
  },
  subtitle2: {
    marginBottom: "15px",
  },
  section: {
    padding: "0px, 15px, 0px, 15px",
  },
  gridContainer2: {
    paddingLeft: "18%",
    paddingRight: "18%",
    paddingBottom: "50px",
  },
  titleStyle: {
    color: "#fff !important",
    background: "#009ee2 !important",
    boxShadow: "10px 0 0 #009ee2, -10px 0 0 #009ee2 !important",
    fontSize: "3rem",
    textTransform: "uppercase",
    "@media (max-width: 833px)": {
      color: "#fff !important",
      background: "#009ee2 !important",
      boxShadow: "10px 0 0 #009ee2, -10px 0 0 #009ee2 !important",
      fontSize: "2.5rem",
      textTransform: "uppercase",
    },
    "@media (max-width: 735px)": {
      color: "#fff !important",
      background: "#009ee2 !important",
      boxShadow: "10px 0 0 #009ee2, -10px 0 0 #009ee2 !important",
      fontSize: "2rem",
      textTransform: "uppercase",
    },
    "@media (max-width: 586px)": {
      color: "#fff !important",
      background: "#009ee2 !important",
      boxShadow: "10px 0 0 #009ee2, -10px 0 0 #009ee2 !important",
      fontSize: "1.4rem",
      textTransform: "uppercase",
    },
    "@media (max-width: 462px)": {
      color: "#fff !important",
      background: "#009ee2 !important",
      boxShadow: "10px 0 0 #009ee2, -10px 0 0 #009ee2 !important",
      fontSize: "1.3rem",
      textTransform: "uppercase",
    },
  },
  button: {
    borderRadius: "50px !important",
    color: "#009EE2",
    width: "50%",
    height: "56px",
    "@media (max-width: 472px)": {
      borderRadius: "50px !important",
      color: "#009EE2",
      width: "60%",
      height: "36px",
    },
    "@media (max-width: 400px)": {
      borderRadius: "50px !important",
      color: "#009EE2",
      width: "60%",
      height: "26px",
    },
  },
  buttonLabel: {
    fontFamily: "Open Sans !important",
    fontSize: "15px !important",
    textAlign: "center",
    letterSpacing: "0.5px !important",
    fontWeight: "600",
    transform: "upperCase",
    color: "#FFFFFF",
  },
  gridContainer: {
    display: "flex",
    backgroundColor: "rgba(237, 236, 229, 0.5)",
    alignItems: "center",
    flexDirection: "column",
  },
  boxContainer: {
    maxWidth: "1055px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
});
