import { makeStyles } from "tss-react/mui";
import { contentContainer } from "../MainContent/MainContent.styles";

export const ContentStyle = makeStyles()({
  contentContainer,
  textfieldBorder: {
    "& .MuiOutlinedInput-root": {
      "&.MuiOutlinedInput-root:hover fieldset": {
        borderColor: "#009ee2",
      },
    },
    borderStyle: "solid",
    borderWidth: "1px",
  },
  titlePosition: {
    paddingLeft: "10px",
    paddingTop: "30px",
  },
  titleStyle: {
    color: "#fff !important",
    background: "#009ee2 !important",
    boxShadow: "10px 0 0 #009ee2, -10px 0 0 #009ee2 !important",
    fontSize: "48px",
  },
  subtitle: {
    paddingRight: "30px",
  },
  button: {
    marginBottom: "30px",
    textTransform: "none",
    fontWeight: 600,
    lineHeight: "26px",
    letterSpacing: "0.5px",
    fontStyle: "normal",
  },
});
