import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { MainContentStyles } from "./MainContent.styles";
import { MainContentLogo } from "./MainContentLogo";
import { ContentProps } from "../../types/contentProps";
import { Content } from "../Content/Content";

export const MainContent = ({
  title,
  shortCodeFromUrl,
  verifierShortName,
  description,
  isShortCodeFromUrlValid,
  displayImage = true,
}: ContentProps) => {
  const { classes } = MainContentStyles();

  return (
    <Box
      className={classes.gridContainer}
      height={!displayImage ? "calc(100vh - 74px - 96px)" : "auto"}
    >
      <Grid container className={classes.boxContainer}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Content
            shortCodeFromUrl={shortCodeFromUrl}
            verifierShortName={verifierShortName}
            description={description}
            isShortCodeFromUrlValid={isShortCodeFromUrlValid}
            title={title}
          ></Content>
        </Grid>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          lg={6}
          display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
        >
          {displayImage && <MainContentLogo />}
        </Grid>
      </Grid>
    </Box>
  );
};
