import { makeStyles } from 'tss-react/mui';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const NavigationBarStyles = makeStyles()({
  appBar: {
    backgroundColor: "#ffffff",
    height: "74px",
    boxShadow: "rgba(0,0,0,0.15) 0px 0px 13.125px 0px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "1024px",
    width: "100%",
    padding: "0 15px",
  },
  flexEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  creditReformLogo: {
    paddingLeft: "8vw",
  },
});
