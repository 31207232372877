import { Box, Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router";

import { PageNotFoundStyle } from "./PageNotFoundStyle";

type VerifierNotFound = {
  verifierNotFound: boolean;
};

export const PageNotFound: React.FC<VerifierNotFound> = ({
  verifierNotFound,
}) => {
  const navigate = useNavigate();
  const { classes } = PageNotFoundStyle();
  return (
    <div>
      {verifierNotFound && (
        <Box
          className={classes.gridContainer}
          height={"calc(100vh - 74px - 96px)"}
        >
          <Grid
            container
            direction="column"
            justifyContent="left"
            alignItems="left"
            spacing={1}
            className={classes.boxContainer}
          >
            <Box>
              <Grid item xs={12}>
                <div className={classes.titlePosition}>
                  <Typography variant="h1">
                    <span className={classes.titleStyle}>
                      {t("pageNotFound.title")}
                    </span>
                  </Typography>
                </div>
                <Grid item xs={12} padding={0}>
                  <div className={classes.subtitle}>
                    <Typography>{t("pageNotFound.subtitle")}</Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ paddingTop: "0px" }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.button}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <div className={classes.buttonLabel}>
                    {t("pageNotFound.button")}
                  </div>
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      )}
    </div>
  );
};
