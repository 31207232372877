import { Box } from "@mui/material";

import { PageStyles } from "./Page.styles";
import { Footer } from "../Footer/Footer";
import { NavigationBar } from "../NavigationBar/NavigationBar";
type ContainerProps = {
  children: React.ReactNode;
};

export const Page: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { classes } = PageStyles();

  return (
    <Box className={classes.box}>
      <NavigationBar />
      <Box className={classes.contentContainer} style={{ flexGrow: 1 }}>
        {props.children}
      </Box>
      <Footer />
    </Box>
  );
};
