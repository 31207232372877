import { makeStyles } from "tss-react/mui";
import { defaultMyEsgContentStyles } from "../MyESGContent/MyESGContent.styles";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const MyESGContent3Style = makeStyles()({
  ...defaultMyEsgContentStyles,
  logo: {
    textAlign: "center",
  },
  paper: {
    display: "inline-flex",
    gap: "15px",
    flexDirection: "column",
    width: "340px",
    boxSizing: "border-box",
  },
  labelButton: {
    fontWeight: 600,
  },
});
