import { adaptV4Theme, createTheme } from "@mui/material";

export const crefoColors = {
  "c-grau-hell": "#edece5",
  h2: "#005790",
  "c-p-1": "#009ee2", // gleiches wie primary
  "c-grau": "#b5b5b5",
  "c-grau-2": "#7f7f7f",
  "c-grau-3": "#4C4C4C",
  primary: "rgb(0, 158, 226)",
  "c-s-2": "#006eb7",
  "c-s-3": "#004884",
  "c-error": "#e85c73",
  "c-error-2": "#e05469",
  "c-success": "#9ccc40",
  "c-warning": "#f8b820",
};

const crefoBreakpoints = {
  xs: 0,
  sm: 320,
  md: 768,
  lg: 1140,
};

// used to access mui breakpoints in crefoTheme
export const defaultTheme = createTheme(
  adaptV4Theme({
    breakpoints: {
      values: crefoBreakpoints,
    },
  })
);

export const crefoTheme = createTheme(
  adaptV4Theme({
    spacing: 15,
    typography: {
      fontFamily: [
        "Open Sans",
        "Arial",
        "Helvetica",
        "sans-serif", // Für Fließtexte kommt ausschließlich die Schriftart Open Sans Regular zum Einsatz.
      ].join(","),
      fontSize: 15,
      fontWeight: "normal",
      body1: {
        fontFamily: "Open Sans",
        fontSize: "15px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.73,
        letterSpacing: "0.5px",
        color: "#4C4C4C",
      },
      h1: {
        fontFamily: ["Arial", "Open Sans", "Averta Std"],
        fontSize: "2.533rem",
        fontWeight: 600,
        letterSpacing: "0.5px",
        color: "rgb(0, 158, 226)",
      },
      h2: {
        fontFamily: ["Arial", "Open Sans", "Averta Std"],
        fontSize: "1.733rem",
        fontWeight: 600,
        letterSpacing: "0.5px",
        color: crefoColors.h2,
      },
      h3: {
        fontFamily: ["Arial", "Open Sans", "Averta Std"],
        fontSize: "1.25rem",
        fontWeight: 600,
        letterSpacing: "0.5px",
        color: "rgb(0, 72, 132)",
        lineHeight: "1.875rem",
      },
    },
    breakpoints: {
      values: crefoBreakpoints,
    },
    palette: {
      text: {
        primary: crefoColors["c-grau-3"], // Die Standard-Schriftfarbe für Fließtexte ist C-Grau 3 (RGB: 76, 76, 76).
      },
      primary: {
        main: crefoColors.primary,
      },
      secondary: {
        main: "#f44336", // rot. zum test
      },
      background: {
        // default: 'rgb(237, 236, 229)' //crefo grau
        default: "rgb(255, 255, 255)", // weiss
      },
      error: {
        main: crefoColors["c-error"],
      },
    },
    overrides: {
      MuiTextField: {
        root: {
          width: "100%",
          borderRadius: "0",
          fontFamily: "Open Sans, sans-serif",
          letterSpacing: "0.5px",
          fontSize: "15px",
          lineHeight: "26px",
          color: "#b5b5b5",
          paddingBottom: "5px",
          "& .MuiOutlinedInput-root": {
            background: "#fff",
          },
          "&:hover": {
            borderColor: "#009ee2",
          },
        },
      },
      MuiToolbar: {
        root: {
          padding: 0,
          "@media (min-width: 0px)": {
            padding: 0,
          },
        },
      },
      MuiButton: {
        // override the styles of all instances of this component
        root: {
          // Name of the rule
          height: "56px",
          width: "100%",
          fontFamily: "Open Sans",
          fontSize: "15px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.73,
          letterSpacing: "0.2px",
          textAlign: "center",
          borderRadius: "50px",
          color: "#ffffff",
          backgroundColor: crefoColors.primary,
          "&.Mui-disabled": {
            backgroundColor: crefoColors["c-grau"],
            color: "white",
          },
          "&:hover": {
            backgroundColor: crefoColors["c-s-2"],
            color: "white",
          },
        },
        outlinedSecondary: {
          backgroundColor: "white",
          color: crefoColors["c-p-1"],
          borderColor: crefoColors["c-p-1"],
          border: "1px solid !important",
          "&:hover": {
            backgroundColor: crefoColors.primary,
            color: "white",
          },
        },
      },
      BulkDeleteButton: {
        color: "rgb(0, 158, 226)",
      },
      MuiPaper: {
        root: {
          boxShadow: "rgba(0,0,0,0.15) 0px 0px 13.125px 0px",
          borderRadius: "0px",
          padding: "30px",
        },
      },
      MuiFormLabel: {
        root: {
          "&.Mui-error": {
            color: crefoColors["c-grau-3"],
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          [defaultTheme.breakpoints.down("sm")]: {
            paddingTop: 10,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 0,
          fontFamily: "Open Sans",
          letterSpacing: "0.2px",
        },
      },
      MuiGrid: {
        item: {
          padding: "0 30px",
        },
      },
      MuiDivider: {
        root: {
          height: 1,
          margin: 0, // Reset browser default style.
          border: "none",
          flexShrink: 0,
          backgroundColor: crefoColors["c-grau"],
        },
        light: {
          height: 31,
          backgroundColor: "rgba(255,255,255,0)",
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255))",
        },
      },
    },
  })
);
