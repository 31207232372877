import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { t } from "i18next";

import { MyFooterStyle } from "./Footer.styles";

export const Footer = () => {
  const { classes } = MyFooterStyle();

  return (
    <footer className={classes.footer}>
      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={12} lg={6} padding="0">
          <Typography style={{ color: "white" }} align="center">
            {t("footer.copyright").replace(
              "{year}",
              `${new Date().getFullYear()}`
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          padding="0"
          style={{ display: "inline" }}
        >
          <ul
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 0,
              listStyleType: "none",
              justifyContent: "center",
              margin: 0,
            }}
          >
            <li>
              <ListItemText>
                <a href="https://www.creditreform.de/datenschutz">
                  <Typography
                    align="center"
                    style={{
                      color: "#FFFFFF",
                    }}
                  >
                    {t("footer.privacy")} &nbsp; | &nbsp;
                  </Typography>
                </a>
              </ListItemText>
            </li>
            <li>
              <ListItemText>
                <a href="https://meine.creditreform.de/nutzungshinweise/">
                  <Typography
                    align="center"
                    style={{
                      color: "#FFFFFF",
                    }}
                  >
                    {t("footer.usageInformation")} &nbsp; | &nbsp;
                  </Typography>
                </a>
              </ListItemText>
            </li>
            <li>
              <ListItemText>
                <a href="https://www.creditreform.com/impressum">
                  <Typography
                    align="center"
                    style={{
                      color: "#FFFFFF",
                    }}
                  >
                    {t("footer.imprint")}
                  </Typography>
                </a>
              </ListItemText>
            </li>
          </ul>
        </Grid>
      </Grid>
    </footer>
  );
};
