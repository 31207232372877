import { makeStyles } from "tss-react/mui";

export const boxContainer = {
  maxWidth: "1055px",
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "60px 0",
} as any;

export const gridContainer = {
  display: "flex",
  backgroundColor: "rgba(237, 236, 229, 0.5)",
  alignItems: "center",
  flexDirection: "column",
} as any;

export const contentContainer = {
  display: "inline-flex",
  gap: "30px",
  justifyContent: "center",
  flexDirection: "column",
} as any;

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const MainContentStyles = makeStyles()({
  gridContainer,
  boxContainer,
});
