import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";

import { MyESGContent1Styles } from "./MyESGContent1.styles";
import score from "../../assets/lottiefiles/score.json";

export const MyESGContent1 = () => {
  const { classes } = MyESGContent1Styles();
  return (
    <Box className={classes.gridContainer} height={"auto"}>
      <Grid container className={classes.gridContainer}>
        <Grid container className={classes.boxContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display={{ xs: "block", sm: "block", md: "block", lg: "block" }}
          >
            <div className={classes.titlePosition}>
              <Typography variant="h1" className={classes.title}>
                {t("myEsgContent1.title")}
              </Typography>
            </div>

            <div className={classes.subtitle}>
              <Typography variant="h3">
                {t("myEsgContent1.subtitle")}
              </Typography>
            </div>

            <div className={classes.myEsgContent1Section}>
              <Typography>{t("myEsgContent1.section1")}</Typography>
            </div>

            <div className={classes.section}>
              <Typography>{t("myEsgContent1.section2")}</Typography>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
          >
            <Player
              autoplay
              loop
              src={score}
              style={{ height: "550px", width: "550px" }}
            ></Player>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
